@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../webfonts/fa-brands-400.eot");
  src: url("../webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
    url("../webfonts/fa-brands-400.woff2") format("woff2"),
    url("../webfonts/fa-brands-400.woff") format("woff"),
    url("../webfonts/fa-brands-400.ttf") format("truetype"),
    url("../webfonts/fa-brands-400.svg#fontawesome") format("svg");
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../webfonts/BrandonGrotesque-Bold.eot");
  src: url("../webfonts/BrandonGrotesque-Bold.eot?#iefix") format("embedded-opentype"),
    url("../webfonts/BrandonGrotesque-Bold.woff2") format("woff2"),
    url("../webfonts/BrandonGrotesque-Bold.woff") format("woff"),
    url("../webfonts/BrandonGrotesque-Bold.svg#BrandonGrotesque-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../webfonts/BrandonGrotesque-Regular.eot");
  src: url("../webfonts/BrandonGrotesque-Regular.eot?#iefix") format("embedded-opentype"),
    url("../webfonts/BrandonGrotesque-Regular.woff2") format("woff2"),
    url("../webfonts/BrandonGrotesque-Regular.woff") format("woff"),
    url("../webfonts/BrandonGrotesque-Regular.svg#BrandonGrotesque-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../webfonts/BrandonGrotesque-Black.eot");
  src: url("../webfonts/BrandonGrotesque-Black.eot?#iefix") format("embedded-opentype"),
    url("../webfonts/BrandonGrotesque-Black.woff2") format("woff2"),
    url("../webfonts/BrandonGrotesque-Black.woff") format("woff"),
    url("../webfonts/BrandonGrotesque-Black.svg#BrandonGrotesque-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../webfonts/BrandonGrotesque-Medium.eot");
  src: url("../webfonts/BrandonGrotesque-Medium.eot?#iefix") format("embedded-opentype"),
    url("../webfonts/BrandonGrotesque-Medium.woff2") format("woff2"),
    url("../webfonts/BrandonGrotesque-Medium.woff") format("woff"),
    url("../webfonts/BrandonGrotesque-Medium.svg#BrandonGrotesque-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../webfonts/BrandonGrotesque-LightItalic.eot");
  src: url("../webfonts/BrandonGrotesque-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("../webfonts/BrandonGrotesque-LightItalic.woff2") format("woff2"),
    url("../webfonts/BrandonGrotesque-LightItalic.woff") format("woff"),
    url("../webfonts/BrandonGrotesque-LightItalic.svg#BrandonGrotesque-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

.fal,
.far {
  font-family: "Font Awesome 5 Pro";
}

.fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

html {
  scroll-behavior: smooth;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  /* font-family: "Point"; */
  font-family: "Brandon Grotesque";
  font-weight: normal;
}

a,
a:link,
a:visited,
a:active,
a:hover,
button,
input,
select,
textarea {
  text-decoration: none;
  outline: none;
  -moz-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-family: "Brandon Grotesque" !important;
}

input,
select,
textarea {
  width: 100%;
  outline: none;
  -moz-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

input[type="radio"],
input[type="checkbox"] {
  width: auto;
  height: auto;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

img {
  max-width: 100%;
}

p {
  font-size: var(--font16);
  line-height: 22px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

:root {
  --maroon: #bf4804;
  --white: #ffffff;
  --black: #000000;
  --grey: #f2f2f2;
  --lightblack: #aaa19b;
  --yellow: #f4ce3b;
  --lightYellow: #fbefd3;
  --font16: 16px;
  --font18: 18px;
  --font20: 20px;
  --font22: 22px;
  --font28: 28px;
  --font45: 45px;
}

.font_red {
  color: var(--maroon) !important;
}

.black_light {
  color: var(--lightblack) !important;
}

.comnpadding {
  padding: 50px 0;
}

.comntitle {
  font-size: var(--font45);
  margin-bottom: 30px;
  text-align: center;
  color: var(--black);
  font-weight: 400;
}

.comntitle span {
  color: var(--maroon);
  font-weight: 500;
}

.comntitle2 {
  font-size: var(--font28);
  margin-bottom: 20px;
  color: var(--black);
  font-weight: 400;
}

.comnbtn {
  font-size: 20px;
  text-transform: uppercase;
  padding: 5px 20px;
  font-weight: bold;
}

.Outline_Btn {
  font-size: var(--font20);
  padding: 5px 30px;
  color: var(--maroon);
  text-transform: capitalize;
  border: 2px solid #bf4804;
  border-radius: 24px;
  background: var(--white);
  display: table;
  margin: 25px auto 10px;
}

/*========================================================================================*/

.logo {
  position: absolute;
  top: -100px;
  width: 100%;
  left: 0;
  text-align: center;
  overflow: hidden;
  padding-bottom: 15px;
  background: #ffffff1a;
  background: linear-gradient(180deg, #ffffff1a, #ffffffe6);
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}

.banner {
  position: relative;
  overflow: hidden;
}

.banner>img {
  width: 100%;
  height: 100vh;
}

.banner_serch {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1200px;
  width: 100%;
  background: var(--white);
  padding: 5px;
  border-radius: 0 0 32px 32px;
}

.banner_serch .MuiContainer-root,
.banner_serch .MuiContainer-root {
  padding: 0 !important;
}

.card {
  background: var(--white);
  border: none;
  box-shadow: 0px 0px 20px 0px #80330738;
  border-radius: 20px;
  overflow: hidden;
  padding: 10px;
}

.card-body {
  padding: 15px 15px 5px 15px;
}

.card-title {
  font-size: var(--font18);
  font-weight: 600;
  height: 40px;
  overflow: hidden;
}

.card span {
  font-size: var(--font16);
  color: var(--maroon);
  display: block;
  margin: 10px 0;
}

.Card_Img {
  position: relative;
  overflow: hidden;
}

.Card_Img img {
  border-radius: 20px;
  height: 250px;
  object-fit: cover;
}

.Card_Img span {
  font-size: var(--font16);
  border-radius: 17px;
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgb(0 0 0 / 70%);
  color: #fff;
  padding: 4px 15px;
  margin: 0;
  /* height: 40px; */
  overflow: hidden;
}

.Card_Img small {
  font-size: var(--font16);
  border-radius: 17px;
  font-weight: 500;
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--white);
  color: var(--maroon);
  padding: 4px 15px;
  margin: 0;
}

.card-text {
  margin-bottom: 10px;
  height: 60px;
  overflow: hidden;
}

.card-footer {
  border: 2px solid var(--lightYellow);
  background: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 30px !important;
  padding: 0;
  margin-bottom: 10px;
}

.card-footer button {
  font-size: var(--font18);
  font-weight: 500;
  background: var(--lightYellow);
  color: var(--black);
  border: none;
  padding: 15px 30px;
  border-radius: 30px;
  text-shadow: none;
}

.card:hover .card-footer {
  border: 2px solid var(--maroon);
}

.card:hover button {
  background: var(--maroon);
  color: var(--white);
  text-shadow: none;
}

.card-footer strong {
  font-size: var(--font18);
  font-weight: 600;
  color: var(--black);
  padding-left: 20px;
}

#popular .item {
  padding: 20px;
}
.popular_items  .slick-slide {
  display: block;
  padding: 15px;
}
.popular_items .card {
  box-shadow: 0px 0px 10px 0px #80330738;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next {
  font-size: var(--font22);
  color: var(--lightblack);
  margin: 10px;
  font-weight: bold;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent;
  color: var(--maroon);
}

#stories .owl-stage-outer {
  padding: 50px 0 20px 0;
}

#stories .item {
  width: 90%;
  margin: auto;
}

.stories_item {
  box-shadow: 0px 0px 22px #80330726;
  border-radius: 20px;
  padding: 30px;
  text-align: center;
}

.stories_item img {
  max-width: 120px;
  margin: -85px auto 10px;
}

.stories_item span {
  font-size: var(--font16);
  color: var(--maroon);
}

.stories_item span {
  font-size: var(--font16);
  color: var(--maroon);
}

.stories_item span {
  font-size: var(--font16);
  color: var(--maroon);
  padding: 10px 20px;
  display: inline-block;
  border-radius: 30px;
}

#stories .owl-item.active.center .item {
  border: 1px solid var(--maroon);
  /* box-shadow: 0px 25px 51px #80330738; */
  box-shadow: 0px 10px 20px #80330738;
  border-radius: 20px;
}

#stories .owl-item.active.center .item span {
  background: var(--lightYellow);
  color: var(--black);
}

footer {
  background: var(--grey);
  padding: 60px 0 30px;
}

.ft {
  text-align: center;
}

.ft_menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 0;
  border-bottom: 1px solid var(--lightblack);
}

.ft_menu a {
  font-size: var(--font18);
  color: var(--black);
  text-transform: uppercase;
  margin: 10px 20px;
  display: block;
  position: relative;
}

.ft_menu a::after {
  content: "";
  width: 0;
  height: 2px;
  background: var(--maroon);
  display: block;
  -moz-transition: all 300ms ease-in-out;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.ft_menu a:hover,
.ft_social a:hover {
  color: var(--maroon);
}

.ft_menu a:hover::after {
  width: 100%;
}

.copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.copy span {
  font-size: var(--font16);
  color: var(--lightblack);
}

.ft_social a {
  font-size: var(--font20);
  color: var(--lightblack);
  margin-left: 20px;
}

.how_works ul::after {
  content: "";
  border: 2px solid #1b0e06;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.how_works ul {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.how_works ul li {
  width: 25%;
  padding: 0 50px;
  display: flex;
  flex-wrap: wrap;
}

.how_works ul li:nth-child(even) {
  flex-direction: column-reverse;
}

.work_icon {
  box-shadow: 0px 0px 26px #80330738;
  width: 74px;
  height: 74px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.work_icon::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 60px;
  border-left: 2px dashed #1b0e06;
}

.work_icon::after {
  content: "";
  width: 16px;
  height: 16px;
  background: var(--yellow);
  border-radius: 50%;
  position: absolute;
  border: 3px solid var(--white);
  box-shadow: 0 0 0px 2px var(--yellow);
}

.work_content {
  position: relative;
  padding-left: 35px;
  min-height: 100px;
}

.work_content h3 {
  font-size: var(--font22);
  color: var(--maroon);
  /* font-weight: 600; */
  margin-bottom: 0;
}

.work_content span {
  font-size: var(--font45);
  color: var(--maroon);
  position: absolute;
  left: 0;
  top: 0;
  line-height: 30px;
}

.work_content p {
  height: 45px;
  overflow: hidden;
}

.how_works ul li:nth-child(odd) .work_icon {
  margin: 0 auto 50px;
}

.how_works ul li:nth-child(odd) .work_icon::before {
  top: 75px;
}

.how_works ul li:nth-child(odd) .work_icon::after {
  bottom: -70px;
  z-index: 1;
}

.how_works ul li:nth-child(odd) .work_content {
  margin-top: 50px;
}

.how_works ul li:nth-child(even) .work_content {
  margin-bottom: 40px;
}

.how_works ul li:nth-child(even) .work_icon {
  margin: 30px auto 0;
}

.how_works ul li:nth-child(even) .work_icon::before {
  bottom: 75px;
}

.how_works ul li:nth-child(even) .work_icon::after {
  top: -70px;
  z-index: 1;
}

.banner button.MuiIconButton-root:hover {
  background-color: var(--maroon);
}

.banner_category_slider {
  width: 45%;
  position: relative;
}

.banner_category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}

.banner_category span {
  font-size: var(--font18);
  color: var(--lightblack);
  display: block;
  text-transform: capitalize;
  margin-bottom: 5px;
}

#Rentals img,
#Storage img {
  max-height: 30px;
  margin: auto;
  display: block;
  width: auto;
  filter: brightness(0) saturate(100%) invert(25%) sepia(84%) saturate(2043%) hue-rotate(14deg) brightness(97%) contrast(97%);
}
.rent .slick-slide img{
  width: auto ;  
  max-height: 24px;
  margin: auto;
  display: block;
  width: auto;
  filter: brightness(0) saturate(100%) invert(25%) sepia(84%) saturate(2043%) hue-rotate(14deg) brightness(97%) contrast(97%);
}

.banner_category .owl-carousel .item {
  padding: 0 10px;
}

.slick-track>span{
  font-size: var(--font16);
  color: var(--black);
  display: block;
  text-align: left;
  color: var(--maroon);
}
.banner_category .item span {
  font-size: var(--font16);
  color: var(--black);
  display: block;
  text-align: center;
}

.filterbtn span {
  color: var(--maroon);
}

.filterbtn button {
  border: none;
  background: none;
}

#Rentals .owl-nav,
#Storage .owl-nav {
  position: absolute;
  width: 100%;
  top: 0px;
  margin: 0;
}

#Rentals .owl-nav button.owl-prev,
#Storage .owl-nav button.owl-prev {
  position: absolute;
  left: 0;
  color: var(--maroon);
  margin: 0;
}

#Rentals .owl-nav button.owl-next,
#Storage .owl-nav button.owl-next {
  position: absolute;
  right: 0;
  color: var(--maroon);
  margin: 0;
}

.banner_category_slider.rent::after {
  content: "";
  width: 1px;
  height: 80%;
  background: var(--lightblack);
  position: absolute;
  right: -15px;
  top: 10%;
}

.MuiBox-root.css-1mek5iq {
  /* background: var(--grey); */
  padding: 0;
  border: 2px solid var(--grey);
  box-shadow: none;
  position: relative;
  z-index: 1;
}

.MuiBox-root.css-1mek5iq::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background: var(--grey);
  height: 100%;
  width: 40%;
  z-index: -1;
  border-radius: 50px;
}

header .MuiBox-root.css-1mek5iq::after {
  width: 57%;
}

.MuiBox-root.css-1mek5iq .Browse_Btn {
  font-size: var(--font20);
  display: block;
  text-transform: capitalize;
  line-height: 22px;
  background: var(--yellow);
  color: var(--black);
  border-radius: 50px;
  padding: 10px 25px;
  font-family: "Brandon Grotesque";
}

.MuiBox-root.css-1mek5iq .Browse_Btn small {
  font-size: var(--font16);
  display: block;
  text-transform: capitalize;
  color: var(--lightblack);
}

.css-ofrcno-MuiGrid-root>.MuiGrid-item {
  padding-left: 0;
}

.SearchIcon_Btn svg {
  fill: var(--maroon);
}

.top_search .SearchIcon_Btn,
header .SearchIcon_Btn {
  margin-right: 10px;
  background: var(--white);
  color: var(--maroon);
  padding: 10px 30px;
  border-radius: 30px;
}

header .SearchIcon_Btn {
  margin: 2px;
}

.top_search .SearchIcon_Btn:hover svg {
  fill: var(--white);
}

.top_search .MuiGrid-container,
header .MuiGrid-container {
  margin: 0 !important;
}

.top_search .MuiGrid-item,
header .MuiGrid-item {
  padding: 0 !important;
  border-right: 1px solid var(--lightblack);
}

.top_search .MuiGrid-item:nth-child(3),
header .MuiGrid-item:nth-child(3),
header .MuiGrid-item:last-child {
  border-right: none;
}

.top_search .MuiGrid-item:first-child,
.top_search .MuiGrid-item:last-child {
  border: none;
}

.top_search .MuiGrid-item input,
header .MuiGrid-item input {
  padding: 0 15px;
  color: var(--black);
  font-family: "Brandon Grotesque";
}

.top_search .MuiGrid-item button,
header .MuiGrid-item button {
  padding: 0 15px;
  color: var(--black);
  min-width: max-content;
}

.top_search .MuiGrid-item button {
  text-transform: capitalize;
}

.top_search .MuiGrid-item button:hover {
  background: none;
}
.top_search .MuiOutlinedInput-root fieldset,
.LIST_PAGE header .MuiOutlinedInput-root fieldset{
  border: none;
}
.Login_Btn {
  position: absolute;
  top: 40px;
  right: 25px;
  z-index: 1300;
}

.Login_Btn button {
  border: none;
  background: var(--white);
  border-radius: 30px;
  padding: 5px 15px;
}

.Login_Btn button:hover,
.Login_Btn button:focus {
  background: var(--maroon);
  color: var(--white);
}

.Login_Btn button svg {
  fill: var(--maroon);
}

.Login_Btn button svg[data-testid="AccountCircleIcon"] {
  fill: var(--black);
  margin-left: 10px;
}

.Login_Btn button:hover svg,
.Login_Btn button:focus svg {
  fill: var(--white);
}

/*========================= Slick Slider Css ===============================*/

.wrap {
  position: relative;
  width: 20%;
  height: 100vh;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.main_slider {
  display: flex;
  height: 100vh;
  flex-wrap: wrap;
  gap: 7px;
  padding: 5px;
}

.main_slider li {
  width: 19.5%;
  height: 100vh;
}

.main_slider .slick-list {
  height: 100vh !important;
}

.slick-slide img {
  display: block;
  width: 100%;
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background: var(--white) !important;
  color: var(--black) !important;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper ul{
  padding: 10px;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper li{
  font-size: var(--font20);
  font-family: "Brandon Grotesque";
      border-bottom: 2px dashed var(--grey);
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper li:last-child{
      border-bottom: none;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: var(--maroon) !important;
  color: var(--white);
}
/* .list-view li {
   width: 31%;
 } */
.sidebar_close {
  display: none;
}

.sidebar {
  border: 2px solid var(--lightYellow);
  border-radius: 20px;
  overflow: hidden;
  padding-bottom: 15px;
  position: relative;
}

.sidebar h3 {
  background: var(--lightYellow);
  padding: 10px 10px 10px 25px;
  font-size: var(--font22);
  margin-bottom: 0;
}

.store_item_list {
  padding: 15px 0;
}

.store_item_list a {
  display: block;
  margin: 10px 0;
  padding-left: 25px;
  font-size: var(--font18);
  color: var(--black);
  display: flex;
  align-items: center;
  font-weight: 500;
}

.store_item_list a img {
  max-width: 25px;
  margin-right: 15px;
  vertical-align: middle;
}

.store_item_list a:hover img,
.store_item_list a.active img {
  filter: brightness(0) saturate(100%) invert(25%) sepia(81%) saturate(4263%) hue-rotate(27deg) brightness(98%) contrast(97%);
}

.store_item_list a:hover,
.store_item_list a:focus,
.store_item_list a.active {
  color: var(--maroon);
}

.LIST_PAGE .MuiGrid-container .MuiGrid-item:first-child {
  display: none;
}

header {
  padding: 25px 0;
  border-bottom: 1px solid var(--grey);
  border-radius: 0px 0px 30px 30px;
}

.filterprice .css-7jxisr {
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: none;
  background: none;
}

.MuiPagination-root .MuiPagination-ul {
  margin: 50px 0;
}

.MuiPagination-root .MuiPagination-ul li button {
  border: none;
  padding: 5px;
  min-width: 26px;
  height: 26px;
}

.MuiPagination-root .MuiPagination-ul li button svg {
  width: 30px;
  height: 30px;
}

.MuiPagination-root .MuiPagination-ul li button.Mui-selected,
.MuiPagination-root .MuiPagination-ul li button:hover,
.MuiPagination-root .MuiPagination-ul li button:focus {
  background-color: var(--lightYellow);
  border-radius: 50%;
}

.MuiPagination-root .MuiPagination-ul li:first-child button,
.MuiPagination-root .MuiPagination-ul li:last-child button {
  opacity: 1;
  color: var(--maroon);
  font-size: var(--font22);
}

.Sort_by {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.Sort_by small {
  font-size: var(--font16);
  font-weight: 500;
  color: var(--black);
}

.Sort_by span {
  font-family: var(--font16);
  color: var(--lightblack);
}

.Sort_by select {
  background: url("../images/select-arrow.png") no-repeat right center;
  background-size: 15px;
  background-position: 94%;
  width: auto;
  min-width: 150px;
  border: 1px solid var(--grey);
  border-radius: 30px;
  padding: 10px 40px 10px 10px;
  margin-left: 15px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.css-24obee-MuiSlider-root .MuiSlider-track,
.css-24obee-MuiSlider-root .MuiSlider-thumb {
  color: var(--maroon) !important;
}

.mob_fiterbtn {
  font-size: var(--font18);
  background: none;
  color: var(--maroon);
  border: 2px solid var(--maroon);
  text-transform: uppercase;
  padding: 5px 20px;
  font-weight: bold;
  display: none;
  border-radius: 10px;
}

.sidebar.show {
  display: block;
  position: fixed;
  top: 5px;
  left: 5px;
  background: #fff;
  z-index: 1300;
  width: 100%;
  max-width: 300px;
  height: calc(100vh - 10px);
  overflow-y: auto;
}

.sidebar_close {
  position: absolute;
  right: 0;
  border: none;
  background: none;
  font-size: 20px;
}

/*============================= item details css start here =============================*/
#Product_Owl {
  width: 96%;
}


#Product_Owl img {
  object-fit: cover;
  border-radius: 20px;
}

#Product_Owl .owl-prev {
  position: absolute;
    left: 0;
    top: 0;
    color: var(--white);
    width: 50%;
    height: 100%;
    margin: 0;
    padding-left: 25px !important;
    text-align: left;
}

#Product_Owl .owl-next {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--white);
  width: 50%;
  height: 100%;
  margin: 0;
  text-align: right;
  padding-right: 25px !important;
}

#Product_Owl .owl-dots {
  counter-reset: slides-num;
  position: absolute;
  bottom: 40px;
  right: 30px;
  margin-top: 0;
  background: var(--white);
  /* padding: 0px 10px; */
  border-radius: 30px;
  width: 50px;
  text-align: center;
}

#Product_Owl .owl-dots:after {
  content:" / " counter(slides-num);
  /* get total number of items - more info on http://www.sitepoint.com/a-little-known-way-to-replace-some-scripts-with-css-counters/ */
  display: inline-block;
  font-size: var(--font16);
  font-weight: 400;
  vertical-align: middle;
  /* padding-left: 5px; */
}

#Product_Owl .owl-dot {
  display: inline-block;
  counter-increment: slides-num;
  /* Increment counter */
  margin-right: 5px;
}

#Product_Owl .owl-dot span {
  display: none;
}

#Product_Owl .owl-dot.active:before {
  /* content: counter(slides-num) " / "; */
  content: counter(slides-num);
  /* Use the same counter to get current item. */
  display: inline-block;
  vertical-align: middle;
  font-size: var(--font16);
  position: absolute;
  left: 10px;
  top: 2px;
  padding-right: 5px;
}

.controlled .comntitle {
  font-weight: 500;
}

.controlled p,
.product_details p {
  font-size: var(--font20);
  line-height: 28px;
}

.rating {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.rating span .fa-star {
  color: var(--maroon);
}

.rating span small {
  font-size: var(--font18);
}

.report span {
  color: var(--lightblack);
  margin-left: 15px;
}

.pricice_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding: 15px 0;
  margin-bottom: 15px;
}

.per_day {
  font-size: var(--font28);
  font-weight: 500;
}

.profile_content {
  display: flex;
  gap: 15px;
  text-align: right;
}
.profile_content img,
.name_img img {
  max-width: 50px;
  border-radius: 50%;
}
.profile_content span {
  color: var(--black);
  font-weight: 500;
  margin-right: 5px;
}

.profile_content div>small {
  display: block;
  font-size: var(--font16);
  color: var(--lightblack);
}

.Red_Btn {
  font-size: var(--font22);
  color: var(--white);
  background-color: var(--maroon);
  font-weight: 300;
  padding: 15px 100px;
  border-radius: 50px;
  border: none;
  text-transform: capitalize;
  display: inline-block;
}

.from_to {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 30px 0;
}

.from_to label {
  font-size: var(--font18);
}

.from_to input {
  font-size: var(--font20);
  height: 50px;
  border-radius: 30px;
  border: none;
  padding: 15px;
  background-color: var(--white);
  box-shadow: 0 3px 20px #80330726;
  max-width: 200px;
}

.you_pay {
  border-bottom: 1px solid #dedede;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.you_pay span {
  font-size: var(--font16);
  color: var(--maroon);
  margin-left: 30px;
}

.other_details>li {
  display: inline-block;
  margin-right: 30px;
}

.other_details>li span {
  font-size: var(--font16);
  display: block;
}

.other_details>li span:nth-child(1) {
  color: var(--lightblack);
}

.product_details>.container {
  background: var(--grey);
  padding: 30px;
  border-radius: 20px;
}

.offers {
  border-right: 1px solid #aaa29b;
  padding-right: 15px;
}

.main_profile h4,
.offers h4 {
  font-size: var(--font18);
  color: var(--maroon);
}

.main_profile ul {
  display: flex;
  flex-wrap: wrap;
}

.main_profile ul li {
  font-size: var(--font16);
  padding: 2px 5px;
  position: relative;
}

.main_profile ul li::after {
  content: ",";
}

.main_profile ul li:last-child:after {
  display: none;
}

.secure_entry {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 30px;
}

.secure_entry li {
  width: 48%;
  display: flex;
  gap: 15px;
}

.condition h6 {
  font-weight: 600;
}

.condition_icon {
  width: 55px;
}

.name_img {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.name_img span {
  font-size: var(--font16);
  font-weight: 500;
}

.main_profile ul>li {
  margin-bottom: 5px;
}

.main_profile ul>li>img {
  max-width: 20px;
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: contain;
}

.main_profile ul>li small {
  font-size: var(--font16);
}

.main_profile h4 {
  margin: 15px 0;
}

.datediv {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}

.datediv span {
  font-weight: 500;
}

.datediv small {
  font-weight: 300;
  display: block;
}

.main_profile .btn_button {
  font-size: var(--font18);
  width: 100%;
  font-weight: 500;
  padding: 10px 30px;
  background-color: var(--white);
  color: var(--maroon);
  border: 2px solid var(--maroon);
  border-radius: 50px;
}

.top_saying {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.people_view {
  display: flex;
  align-items: center;
  gap: 15px;
}

.star_rating strong {
  font-size: 60px;
  font-weight: 500;
  line-height: 50px;
}
.star_rating fieldset{
  margin: 0;
}
.star_rating fieldset span,
.item__detail_rating{
  display: flex;
}
.item__detail_rating fieldset{
  margin-right: 10px;
}
.item__detail_rating span svg,
.star_rating fieldset span svg{
  color: var(--maroon);
  width: 25px;
}
.star_rating span {
  display: block;
}

.star_rating span i {
  color: var(--maroon);
}

.people_view p {
  margin: 0;
}

.people_view span {
  display: block;
  color: var(--grey);
}

.top_saying .btn_button {
  background: none;
  border: none;
  padding: 0;
  color: var(--coffe);
  text-decoration: underline;
}

.customer_review {
  margin-top: 50px;
}

.customer_review li {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #dedede;
}

.customer_review li:last-child {
  /* padding-bottom: 0px;
  margin-bottom: 0px; */
  border-bottom: none;
}

.customer_name h6 {
  font-size: var(--font20);
  text-transform: capitalize;
}

.customer_name h6 span {
  font-size: 14px;
  color: var(--maroon);
}

.customer_review li:last-child .btn_button {
  display: table;
  margin: 50px auto 0;
  font-weight: 500;
  background-color: var(--white);
  color: var(--maroon);
  border: 2px solid var(--maroon);
}

.location_map iframe {
  width: 100%;
  border-radius: 20px;
}

.privacey_box h4 {
  font-size: var(--font20);
  color: var(--maroon);
  margin-bottom: 25px;
}

.terms_provision li {
  margin-bottom: 7px;
  font-size: var(--font18);
  position: relative;
  padding-left: 20px;
}

.terms_provision li::before {
  content: "\f054";
  font-size: var(--font16);
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  color: var(--maroon);
  position: absolute;
  left: 0;
  top: 3px;
}

.Available_Storage {
  margin-top: 50px;
}

.Available_Storage h2 {
  font-size: var(--font20);
  color: var(--maroon);
  font-weight: 500;
  margin-bottom: 20px;
}

.list_storage {
  display: flex;
  flex-wrap: wrap;
}

.list_storage li {
  width: 20%;
  font-size: var(--font18);
  color: var(--black);
  padding: 10px 5px 5px 20px;
  position: relative;
}

.list_storage li::before {
  content: "\f054";
  font-size: var(--font16);
  font-family: "Font Awesome 5 Pro";
  font-weight: 600;
  position: absolute;
  left: 0;
  color: var(--maroon);
}

.location_map {
  height: 500px;
}

/*============================= item details css end here =============================*/


/*============================= Login Form css start here =============================*/

.Login_form {
  padding: 0px;
  background: var(--lightYellow);
  outline: none;
  border-radius: 8px;
  width: 80%;
  max-width: 400px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.tabs_btn button {
  font-size: var(--font-18);
  width: 50%;
  background: var(--white);
  color: var(--black);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 600;
}

.tabs_btn button svg {
  margin-right: 10px;
  padding: 3px;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  background: var(--maroon);
  color: var(--white);
}

.tabs_btn button.Mui-selected {
  background: var(--lightYellow);
  color: var(--maroon);
}

.css-1aquho2-MuiTabs-indicator {
  background: none !important;
}

.sign_Btn {
  background: none;
}

/*============================= Login Form css end here =============================*/

/*============================= My Account css start here =============================*/

.afterLogin button{
  background: var(--grey);
}
.second_header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 40px);
}

.inner_logo{
  position: initial;
}
.inner_logo img{
  max-width: 250px;
}
.head_link a::after{
  content: "";
  width: 0;
  height: 1px;
  background: var(--maroon);
  position: absolute;
  bottom: -29px;
  left: 0;
  -moz-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}
.head_link a {
  font-size: var(--font20);
  color: var(--maroon);
  padding: 10px 20px;
  display: inline-block;
  position: relative;
}
/* .head_link a.active{
  background: var(--maroon);
  color: var(--white);
} */

.head_link a:hover::after,
.head_link a.active::after {
  width: 100%;
}
.head_link a.seller_add_btn{
  border: 1px solid var(--maroon);
  color: var(--maroon);
  border-radius: 50px;
  font-weight: 500;
  text-transform: capitalize;
}
.head_link a.seller_add_btn:hover{
  background: var(--maroon);
  color: var(--white);
}
.head_link a.seller_add_btn i{
  margin-right: 10px;
}
.head_link a.seller_add_btn::after {
  display: none;
}
.seller_add_btn:hover,
.seller_add_btn:focus,
.seller_add_btn.active{
  background: var(--black);
  color: var(--white) !important;
}
/*============================= My Account css end here =============================*/


.add_list_box{
  /* background: var(--grey);
  box-shadow: 0 0 15px #dadada;
  border-radius: 10px; */
  padding: 30px;
}
.add_list_box .form-group label {
  font-size: var(--font22);
  display: block;
  margin-bottom: 10px;
}
.add_list_box .comntitle{
  font-size: var(--font28);
  text-align: left;
  background: var(--black);
  color: var(--white);
  padding:15px 35px;
  text-transform: capitalize;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;
}


.add_remove .yellow_Btn {
  background: var(--yellow) !important;
  color: var(--black);
}
.add_remove .black_Btn {
  background: var(--black) !important;
  color: var(--white);
}
.add_list_box .Red_Btn{
  margin: auto;
  display: table;
}

.text_area_box {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 15px;
}
.text_area_box i{
  font-size: var(--font22);
  padding: 15px 5px;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root:focus .MuiOutlinedInput-notchedOutline {
  border-color: var(--maroon) !important;
}
#locationInput ,
.rti--container{
  height: 56px !important;
  border: 1px solid #d5d5d5 !important;
}
input#locationInput:focus{
  outline: none !important;
  box-shadow: none !important;
}

.upliad_img>button {
  font-size: var(--font18);
  background: var(--grey);
  border: 2px solid var(--lightblack);
  border-radius: 50px;
  padding: 10px 30px;
}

.upload_box {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}



/*============================= book css start here (1-8-24) =============================*/

.book_tittle {
  font-size: var(--font28);
}

.black_font_weight {
  font-weight: 600;
}

.almost_part .book_tittle {
  font-weight: 600;
  border-bottom: 1px solid var(--lightblack);
  padding-bottom: 15px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.almost_part .book_tittle span{
  color: var(--maroon);
  font-weight: 500;
  font-size: var(--font18);
}
.points_colum li {
  font-size: var(--font20);
  position: relative;
  padding-left: 15px;
  padding-bottom: 10px;
}

.points_colum li::before {
  content: "\f105";
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  color: var(--maroon);
  position: absolute;
  top: 2px;
  left: 0;
}

.featuring {
  margin-top: 30px;
}

.featuring h6 {
  font-size: var(--font20);
  color: var(--maroon);
  margin-bottom: 30px;
}

.menu_avilable {
  margin-top: 30px;
}

.menu_avilable li {
  display: inline-block;
  width: 24%;
  margin-bottom: 15px;
}

.featuring input {
  font-size: 1rem;
  line-height: 1.5;
  padding: 11px 23px;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0;
  outline: 0;
  background-color: transparent;
}

.checkbox-button__input {
  opacity: 0;
  position: absolute;
}

.checkbox-button__control {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
  background-color: inherit;
  color: var(--maroon);
  border: 2px solid #666;
}

.checkbox-button__input:checked+.checkbox-button__control:after{
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: var(--maroon);
  transform: translate(-50%, -50%);
}

.Featuring_check {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
}
.featuring fieldset{
  width: 100%;
}
.Check_List {
  flex-direction: row !important;
}

.check_label_list {
  width: 24% !important;
  margin-bottom: 15px;
}
.featuring label{
  margin: 0;
  position: relative;
  padding-left: 30px;
  font-size: var(--font20);
  font-family: 'Brandon Grotesque';
}
.featuring label span:first-child{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-color: inherit;
  color: var(--maroon);
  border: 2px solid #666;
  border-radius: 0;
}
.featuring label span{
  padding: 0;
  position: relative;
  display: none;
  /* white-space:nowrap; */
}
.featuring label span:last-child{
  padding: 0;
  position: relative;
  display: block;
  font-size: var(--font18);
  font-family: 'Brandon Grotesque';
  line-height: 20px;
}
.featuring label span.Mui-checked span{
  display: none;
}
.featuring label span.Mui-checked {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height:20px;
  border-radius: 2px;
  border: 2px solid var(--maroon);
}
.featuring label span.Mui-checked::before{
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    border: none;
    background-color: var(--maroon);
}
.featuring label span svg{
  display: none;
}
.featuring label>span input{
  position: absolute;
  z-index: 1;
  opacity: 0;
}
.checkbox-button__input:checked+.checkbox-button__control {
  border-color: var(--maroon);
}

.checkbox-button__control {
  transform: scale(0.75);
}

.checkbox-button .checkbox-button__label {
  font-size: var(--font18);
  vertical-align: middle;
}

.open_from {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
}

.click_to_open {
  display: flex;
  align-items: center;
}

.click_to_open span {
  font-size: var(--font20);
  color: var(--maroon);
  display: inline-block;
  margin-left: 15px;
}

.click_to_open input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.click_to_open label {
  cursor: pointer;
  text-indent: -9999px;
  width: 70px;
  height: 34px;
  background: var(--white);
  box-shadow: 0 3px 22px #80330726;
  display: block;
  border-radius: 100px;
  position: relative;
}

.click_to_open label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 22px;
  height: 22px;
  background: var(--lightblack);
  border-radius: 90px;
  transition: 0.3s;
}

.click_to_open input:checked+label {
  background: var(--white);
}

.click_to_open input:checked+label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
  background: var(--maroon);
}

.click_to_open label:active:after {
  width: 22px;
}

.input_clikc_to_open {
  width: 50%;
  display: none;
}

.input_clikc_to_open input {
  height: 50px;
  padding-left: 15px;
  border: none;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0 3px 22px #80330726;
}

.something_telling {
  margin-top: 30px;
}

.something_telling h6 {
  font-size: var(--font20);
  margin-bottom: 30px;
  font-weight: 600;
}

.something_telling textarea {
  font-size: var(--font18);
  padding: 15px;
  resize: none;
  height: 150px;
  box-shadow: 0 3px 22px #80330726;
  border: none;
  border-radius: 10px;
}

.something_telling p {
  margin-top: 30px;
}

.something_telling .comnbtn {
  background: var(--maroon);
  color: var(--white);
  font-weight: normal;
  text-transform: capitalize;
  border-radius: 30px;
  border: none;
  display: table;
  margin: 30px 0 0 auto;
}

.secure_storage {
  background: var(--white);
  box-shadow: 0 3px 22px #80330726;
  padding: 15px;
  border-radius: 20px;
}

.secure_storage h6 {
  font-size: var(--font18);
  padding: 15px 0;
}

.price_day {
  font-size: var(--font22);
  font-weight: 500;
}

.secure_storage .profile_content {
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.secure_storage .profile_content small {
  font-size: var(--font16);
  display: block;
  color: var(--lightblack);
}

.secure_storage .from_to {
  margin: 15px 0;
}

.secure_storage .from_to input {
  max-width: 168px;
  box-shadow: none;
  background: var(--grey);
}

.secure_storage .from_to span {
  font-size: var(--font18);
  color: var(--lightblack);
}

.edit_date {
  position: relative;
}

.edit_date i {
  font-size: var(--font16);
  color: var(--lightblack);
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate(-50%, -50%);
}

.fess_details li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 5px;
}

.fess_details li span {
  font-size: var(--font16);
}

.fess_details li:last-child {
  border-top: 1px solid #dedede;
  padding-top: 15px;
}

.fess_details li:last-child span {
  font-size: var(--font20);
}
.modify_date {
  background: url(../images/Edit.svg) no-repeat var(--grey) !important;
  background-size: 16px 16px !important;
  background-position: 93% center !important;
}
.react-datepicker__tab-loop {
  position: absolute;
}
/*============================= book css end here (1-8-24) =============================*/


/*============================= Account css start here (5-8-24) =============================*/

.main_ul {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.main_ul li {
  width: 31%;
}

.property {
  background: var(--white);
  box-shadow: 0 3px 22px #80330726;
  border-radius: 20px;
  padding: 15px;
}

.property_images {
  position: relative;
  width: 100%;
}

.property_images img {
  width: 100%;
}

.property_content h5 {
  font-size: var(--font22);
  color: var(--maroon);
  margin: 15px 0;
}

.product_date {
  display: flex;
  flex-wrap: wrap;
}

.product_date li {
  width: 50%;
}

.product_date li strong {
  font-size: var(--font18);
}

.product_date li span {
  font-size: var(--font16);
  display: block;
}

.remember_day {
  font-size: var(--font20);
  margin: 15px 0;
  display: block;
}

.property_button {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #FFD3A6;
  border-radius: 30px;
}

.Total_cost {
  text-align: center;
  padding: 0 0 0 15px;
}

.Total_cost strong {
  font-size: var(--font18);
}

.Total_cost span {
  font-size: var(--font16);
  display: block;
}

.view_btn {
  font-size: var(--font18);
  background: var(--lightYellow);
  color: var(--black);
  padding: 15px 30px;
  border-radius: 30px;
}

.view_btn:hover {
  background: var(--maroon);
  color: var(--white);
}


/*============================= Account css end here (5-8-24) =============================*/


/*============================= review css start here (7-8-24) =============================*/

.review .rating {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  overflow: hidden;
  flex-direction: row-reverse;
  /* height: 150px; */
  position: relative;
}

.rating-0 {
  filter: grayscale(100%);
}

.rating>input {
  display: none;
}

.rating>label {
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-top: auto;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23e3e3e3' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 76%;
  /* transition: 0.3s; */
}

.rating>input:checked~label,
.rating>input:checked~label~label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23fcd93a' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  filter: brightness(0) saturate(100%) invert(32%) sepia(91%) saturate(4018%) hue-rotate(19deg) brightness(90%) contrast(97%);
}

.rating>input:not(:checked)~label:hover,
.rating>input:not(:checked)~label:hover~label {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='126.729' height='126.73'%3e%3cpath fill='%23d8b11e' d='M121.215 44.212l-34.899-3.3c-2.2-.2-4.101-1.6-5-3.7l-12.5-30.3c-2-5-9.101-5-11.101 0l-12.4 30.3c-.8 2.1-2.8 3.5-5 3.7l-34.9 3.3c-5.2.5-7.3 7-3.4 10.5l26.3 23.1c1.7 1.5 2.4 3.7 1.9 5.9l-7.9 32.399c-1.2 5.101 4.3 9.3 8.9 6.601l29.1-17.101c1.9-1.1 4.2-1.1 6.1 0l29.101 17.101c4.6 2.699 10.1-1.4 8.899-6.601l-7.8-32.399c-.5-2.2.2-4.4 1.9-5.9l26.3-23.1c3.8-3.5 1.6-10-3.6-10.5z'/%3e%3c/svg%3e");
  filter: brightness(0) saturate(100%) invert(32%) sepia(91%) saturate(4018%) hue-rotate(19deg) brightness(90%) contrast(97%);
}

.emoji-wrapper {
  /* width: 100%;
    text-align: center; */
  height: 70px;
  overflow: hidden;
  position: absolute;
  top: -12px;
  right: 0;
}

.emoji-wrapper:before,
.emoji-wrapper:after {
  content: "";
  height: 15px;
  width: 100%;
  position: absolute;
  left: 0;
  z-index: 1;
}

.emoji-wrapper:before {
  top: -5px;
  background: linear-gradient(to bottom, white 0%, white 35%, rgba(255, 255, 255, 0) 100%);
}

.emoji-wrapper:after {
  bottom: 0;
  background: linear-gradient(to top, white 0%, white 35%, rgba(255, 255, 255, 0) 100%);
}

.emoji {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
}

.emoji>svg {
  margin: 8px 0;
  width: 45px;
  height: 53px;
  flex-shrink: 0;
}

#rating-1:checked~.emoji-wrapper>.emoji {
  transform: translateY(-69px);
}

#rating-2:checked~.emoji-wrapper>.emoji {
  transform: translateY(-137px);
}

#rating-3:checked~.emoji-wrapper>.emoji {
  transform: translateY(-207px);
}

#rating-4:checked~.emoji-wrapper>.emoji {
  transform: translateY(-276px);
}

#rating-5:checked~.emoji-wrapper>.emoji {
  transform: translateY(-343px);
}

.review .feedback {
  max-width: 320px;
  width: 100%;
  padding: 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
}

.review .review_form input {
  font-size: var(--font16);
  background: var(--white);
  box-shadow: 0 3px 22px #80330726;
  border: none;
  height: 50px;
  border-radius: 10px;
  padding-left: 15px;
  margin-bottom: 30px;
}

.review .review_form textarea {
  font-size: var(--font16);
  background: var(--white);
  box-shadow: 0 3px 22px #80330726;
  padding-top: 15px;
  padding-left: 15px;
  border-radius: 10px;
  height: 250px;
  resize: none;
  border: none;
  margin-bottom: 30px;
}

.review_form .Outline_Btn {
  border: none;
  background: var(--maroon);
  color: var(--white);
}


/*============================= review css end here (7-8-24) =============================*/


/*============================= profile css start here (8-8-24) =============================*/

.avatar-upload {
  position: relative;
  max-width: 155px;
  margin: 0 auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 20px;
  z-index: 1;
  bottom: -5px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: var(--maroon);
  border: 3px solid #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
  background: var(--maroon);
}

.avatar-upload .avatar-edit input+label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: #757575;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 143px;
  height: 143px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #f8f8f8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.avatar-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-upload .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.first_input {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.username {
  width: 47%;
  position: relative;
}

.username .fa-user {
  font-size: var(--font20);
  color: var(--lightblack);
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0%, -50%);
}

.username input {
  font-size: var(--font16);
  height: 50px;
  border-radius: 10px;
  border: none;
  padding: 15px;
  padding-right: 50px;
  color: var(--black);
  background-color: var(--white);
  box-shadow: 0 3px 20px #80330726;
  max-width: 100%;
}

.username .fa-map-marker-alt {
  font-size: var(--font20);
  color: var(--lightblack);
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0%, -50%);
}

.full_width {
  width: 100%;
}

.full_width textarea {
  font-size: var(--font16);
  background: var(--white);
  box-shadow: 0 3px 22px #80330726;
  padding-top: 15px;
  padding-left: 15px;
  border-radius: 10px;
  resize: none;
  border: none;
  margin: 30px 0;
  padding-right: 30px;
  height: 60px;
}

.dayto_day {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.dayto_day li {
  width: 40%;
}

.dayto_day li .checkbox-button {
  width: 100%;
  border-bottom: 1px solid #dedede;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.dayto_day li.active .timeupdate {
  display: none;
}

.dayto_day li.active .unavailable_vid {
  display: block;
}

.dayto_day input {
  font-size: 1rem;
  line-height: 1.5;
  padding: 11px 23px;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 0;
  outline: 0;
  background-color: transparent;
}

.timeupdate {
  display: inline-block;
  margin-left: 30px;
}

.timeupdate input {
  max-width: 80px;
  line-height: unset;
  padding: 5px 15px;
  border-radius: 8px;
  text-align: center;
}

.unavailable_vid {
  font-size: var(--font18);
  display: none;
}

.profile_bnt {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
}

.simple_btn {
  border: none;
  background: none;
  font-size: var(--font22);
}

.bg_btn {
  font-size: var(--font22);
  background: var(--maroon);
  color: var(--white);
  padding: 10px 45px;
  border: none;
  border-radius: 30px;
}

/*============================= profile css end here (8-8-24) =============================*/

/*=================================

Add Item Step Form Css Start Here
 
=================================*/

.add_step {
  position: relative;
  border: 2px solid #FFD3A6;
  border-radius: 20px;
  padding: 20px;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
.add_item_form .MuiContainer-root{
  padding-top: 0;
}
.add_item_form .MuiStep-root{
  border-right: 1px solid var(--lightblack);
}
.add_item_form .MuiStep-root:last-child{
  border-right: none;
}
.add_item_form {
  padding:50px 20px;
}
.add_item_form h4{
  font-size: var(--font28);
  margin-bottom: 20px;
}
.add_item_form p{
  font-size: var(--font20);
  color: var(--lightblack);
}


.add_item_form .MuiFormControl-root{
  margin-bottom: 30px !important;
}
.add_item_form .upload_box{
  display: block;
}
.add_item_form .upliad_img>button{
 border: none;
 width: 100%;
 border-radius: 10px;
 height: 50px;
 position: relative;
 text-align: left;
}
.add_item_form .upliad_img>button::before{
  content: "\f0ee";
  font-family: "Font Awesome 5 Pro";
color: var(--maroon);
position: absolute;
right: 20px;
}
.MuiFormControl-root
.css-ka7ti6-MuiAutocomplete-root{
  box-shadow: 0 3px 22px #80330726;
  border: none;
  border-radius: 10px;
}
.add_item_form fieldset{
  border-color:var(--grey);
}
.add_item_form .step_img img{
  max-width: 370px;
  margin: auto;
  display: block;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active,
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
  color: var(--maroon) !important;
}


.add_item_form .MuiButton-contained:hover{
  color: var(--black);
  background: var(--yellow);
  border: 2px solid var(--yellow);
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color: var(--maroon) !important;
}
.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
.Mui-focused .MuiOutlinedInput-notchedOutline,
fieldset:hover,
.MuiInputBase-root:hover,
.MuiInputBase-root:hover fieldset{
  border-color: var(--maroon) !important;
}

.react-datepicker {
  z-index: 11;
}

.add_item_form .custom_radio {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-start;
  margin-top: 40px;
}
.add_item_form .custom_radio label{
  width: 100%;
  max-width: 250px;
  box-shadow: 0px 3px 22px #80330726;
  border-radius: 10px;
  padding: 5px;
}
.add_item_form .custom_radio label span{
  font-size: var(--font22);
  font-family: "Brandon Grotesque";
}
.add_item_form .custom_radio label span svg{
  fill: var(--lightblack);
}
.add_item_form .custom_radio label span.Mui-checked svg{
  fill: var(--maroon);
}
.step_inner .MuiGrid-container{
  margin: 0;
}

.add_item_form .nxt_prv_btn {
  display: flex;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  margin-top: 20px;
}
.add_item_form .nxt_prv_btn button{
  border-radius: 50px;
  width: 100%;
  max-width: 200px;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: var(--maroon) !important;
  opacity: 0.3;
}

.add_item_form .MuiButton-text {
  font-size: var(--font18);
  border: 2px solid var(--maroon);
  color: var(--maroon);
}

.add_item_form .MuiButton-contained {
  font-size: var(--font18);
  color: var(--white);
  background: var(--maroon);
  border: 2px solid var(--maroon);
  margin-left: 15px;
}

.MuiChip-filledDefault{
  border-radius: 5px !important;
  background: var(--lightblack);
}
.MuiChip-filledDefault svg{
  background: var(--white);
  fill: var(--maroon);
}
.step_inner h5.comntitle{
  display: none;
}

.comnbox{
  padding: 30px;
  box-shadow: 0px 3px 22px #80330726;
  border-radius: 10px;
}

.upload_box .image-item{
  border: none;
  width: 70px;
  height: 70px;
  position: relative;
  overflow: visible;
}
.upload_box .image-item img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.calender{
  background: url("../images/calender.svg") no-repeat;
  background-position: 96% center;
  border: 1px solid var(--grey);
  box-shadow: 0px 3px 22px #80330726;
  border-radius: 10px;
  font-size: var(--font18);
  height: 56px;
}
.step_inner #locationInput,
.step_inner #locationInput:focus,
.step_inner input[name="facility_offers[0].title"] {
  border: none !important;
  box-shadow: 0px 3px 22px #80330726 !important;
  border-radius: 10px;
  font-size: var(--font18);
  height: 56px;
}
.step_inner input[name="facility_offers[0].title"] {
  padding: 0;
}

.add_item_form .black_Btn.MuiButton-contained:hover{
  background: var(--yellow) !important;
}
.add_item_form .Outline_Btn.MuiButton-contained{
  font-size: var(--font18);
  color: var(--maroon);
  background: var(--white);
  border: 2px solid var(--lightblack);
  border-radius: 50px;
}
.add_item_form .Outline_Btn.MuiButton-contained i{
  margin-right: 10px;
}
.style1 li{ 
  font-size: var(--font18);
  color: var(--black);
  padding: 0px 0px 0px 25px;
  position: relative;
  margin: 7px 0;
}
.style1 li::before {
  /* content: "\f178"; */
  content: "\f00c";
  font-size: var(--font16);
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  position: absolute;
  left: 0;
  color: var(--maroon);
}


.add_item_form .Mui-active img,
.add_item_form .Mui-completed img{
  filter: brightness(0) saturate(100%) invert(29%) sepia(75%) saturate(1358%) hue-rotate(357deg) brightness(98%) contrast(100%);
} 

.F_offers {
  background: var(--grey);
  padding: 25px;
  margin-bottom: 25px;
}

.F_offers input{
  background: var(--white);
  padding: 15px;
  height: 56px;
}
.F_offers  .text_area_box div > div{
  background: var(--white);
}
.F_offers  .text_area_box  button.black_Btn,
.terms  .text_area_box  button.black_Btn {
  background: none !important;
  padding: 0;
  border: none;
  color: #000;
  box-shadow: none;
  margin: 0;
}
.add_item_form .F_offers  .text_area_box .MuiFormControl-root ,
.terms  .text_area_box .MuiFormControl-root {
  margin-bottom: 0 !important;
}

.terms button.yellow_Btn {
  font-size: var(--font18);
  color: var(--maroon);
  background: var(--white) !important;
  border: 2px solid var(--lightblack);
  border-radius: 50px;
}
.terms button.yellow_Btn i{
  margin-right: 10px;
}

.head{
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--maroon);
}
.head i{
  border: 2px solid;
  padding: 5px;
  border-radius: 5px;
}

.input_clikc_to_open_cus {
  width: 100%;
  max-width: 500px;
}
.slick-prev:before{
  content: "\f053";
  font-family: "Font Awesome 5 Pro";
  color: var(--maroon);
  font-size: var(--font16);
}
.slick-next:before{
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  color: var(--maroon);
  font-size: var(--font16);
}
.slick-prev {
  left: -5px;
}
.slick-next {
  right: -10px;
}

.booking_Featuring_available_storage .check_label_list,
.almost_part .featuring label{
  width: 50% !important;
}
.almost_part .featuring label{
  margin-bottom: 20px;
}

.booking_Featuring_available_storage .image-item {
  width: 70px;
  height: 50px;    
  border: none;
  overflow: inherit;
}
.booking_Featuring_available_storage .image-item button {
  width: 16px;
  height: 16px;
  line-height: 16px;  
  font-size: 12px;
}
.booking_Featuring_available_storage .upliad_img {
  position: relative;
  width: 100%;
}
/* .booking_Featuring_available_storage .upliad_img>button {
  position: absolute;
  top: 0;
} */

.featuring label span .css-70qvj9{
  align-items: flex-start;
}
.View_inquire_Default_Items>div{
  width: 50%;
}
.add_req {
  display: flex;
  flex-wrap: wrap;
}
.add_req img{
  margin-left: 15px;
}
.view_in_img {
  display: flex;
  margin: 15px 0;
  width: 100%;
}
.View_inquire_Default_Items label{
  display: inline-block;
}

.View_inquire_Default_Items span{
  vertical-align: top;
}

.borrower_booking .booking_Featuring_available_storage label{
  width: auto !important;
}

.status_0{
  background: var(--yellow);
  padding: 1px 10px;
  border-radius: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.status_1{
  background:var(--maroon);
  color: var(--white);
  padding: 1px 10px;
  border-radius: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.status_2{
  background: green;
  color: var(--white);
  padding: 1px 10px;
  border-radius: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.status_3{
  background: red;
  color: var(--white);
  padding: 1px 10px;
  border-radius: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.storage_img{
  position: relative;
}

/* ==============11-9-24 css here ===============*/

.account .Sort_by{
  display: flex;
  justify-content: flex-end;
}
.btn_center{
  display: flex;
  justify-content: center;
  gap: 30px;
}

.property_type{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 48px;
}
/* ==============11-9-24 css end here ===============*/

.calendar:before {
  content: "\f073";
  position: absolute;
  left: 0;
  font-family: "Font Awesome 5 Pro";
  top: -3px;
  font-size: 20px;
  color: #be4804;
}
.calendar {
  position: relative;
  padding-left: 15px;
}