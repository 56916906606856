:root {
  --maroon: #bf4804;
  --white: #ffffff;
  --black: #000000;
  --grey: #f2f2f2;
  --lightblack: #aaa19b;
  --yellow: #f4ce3b;
  --lightYellow: #fbefd3;
  --font16: 16px;
  --font18: 18px;
  --font20: 20px;
  --font22: 22px;
  --font28: 28px;
  --font45: 45px;
}
.list-view {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.list-view li {
  width: 23%;
}
.list_items {
}

.carousel-indicators {
  display: none;
}

/* customDateRange.css */
.custom-calendar-wrapper {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.custom-calendar-header {
  /* background-color: #F4CE3B; Use the same color for the header */
  color: white;
  padding: 10px;
}

.custom-calendar-day {
  color: #f4ce3b;
}

.custom-calendar-day-selected {
  background-color: #f4ce3b !important; /* Use the same color for selected dates */
  color: white !important;
}

.custom-date-range-options {
  display: flex;
  flex-direction: column;
  margin-top: 10px; /* Adjust as needed */
}

.custom-date-range-options > * {
  margin: 5px 0; /* Add some spacing between items */
}

.custom-calendar-footer {
  background-color: white;
  padding: 10px;
}

.custom-calendar-weekday {
  color: #f4ce3b;
}

.cube-grid-container {
  height: 100vh; /* Adjust the height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  display: flex;
  align-items: center;
  justify-content: center;
}

.cube-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns */
  gap: 10px; /* Adjust gap between cells */
  max-width: 600px; /* Adjust max-width of the grid */
  margin: auto;
}

.cell {
  background-color: #f0f0f0; /* Background color of each cell */
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow to cells */
}

.cell img {
  max-width: 100%;
  height: auto;
  display: block;
  transform: rotate(90deg); /* Rotate images vertically */
  transform-origin: center center;
}

.headerPadding {
  padding: 25px 0;
}

.react-datepicker__day--highlighted {
  background-color: #ffcc00;
  color: #fff;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.image-item {
  position: relative;
  width: 150px;
  height: 150px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.image-item button {
  position: absolute;
  top: -5px;
  right: -5px;
  background: var(--lightYellow);
  color: var(--black);
  border: none;
  border-radius: 50px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  padding: 0;
  font-weight: bold;
}

.input_clikc_to_open_cus input {
  height: 30px !important;
  padding:5px !important;
  border: none !important;
  border-radius: 10px !important;
  /* background: var(--white) !important;
   box-shadow: 0 3px 22px #80330726 !important; */
}

.add_step .MuiStepConnector-horizontal,
.add_step .MuiStepLabel-iconContainer {
  display: none;
}
.add_step .MuiStepLabel-label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-input {
  border-color: red;
}

/* Styling the placeholder in case of error */
.error-input::placeholder {
  color: red;  /* Placeholder text in red when there's an error */
  opacity: 1;  /* Ensures the placeholder is fully visible */
}

/* Optional: Style for the error message */
.error-text {
  color: red;
  font-size: 0.875rem;
  margin-top: 4px;
}