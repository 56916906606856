@media only screen and (min-width: 1920px) {
  .container {
    max-width: 1750px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1900px) {
  .container {
    max-width: 1550px;
  }
  .list-view li {
    width: 31.2%;
  }
  :root {
    --font16: 16px;
    --font18: 18px;
    --font20: 20px;
    --font22: 22px;
    --font28: 28px;
    --font45: 45px;
  }
}

@media only screen and (min-width:1400px) and (max-width:1590px) {
    :root {
        --font16: 15px;
        --font18: 16px;
        --font20: 18px;
        --font22: 20px;
        --font28: 24px;
        --font45: 40px;
    }
    .comnpadding {
        padding: 50px 0;
    }
    .comntitle2,
    .per_day{
        font-size: var(--font22);
    }
    .controlled p,
    .product_details p {
        font-size: var(--font18);
        line-height: 24px;
    }
    .you_pay {
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .Red_Btn{
            padding: 10px 80px;
    }
    .second_header {
        width: calc(100% - 70px);
    }
    .afterLogin button {
        top: -5px;
    }

}
@media only screen and (min-width: 1200px) and (max-width: 1390px) {
  :root {
    --font16: 16px;
    --font18: 18px;
    --font20: 20px;
    --font22: 22px;
    --font28: 28px;
    --font45: 45px;
  }
  .comntitle2 {
    font-size: var(--font22);
  }
  .controlled p,
  .product_details p {
    font-size: var(--font16);
    line-height: 24px;
  }
  .comnpadding {
    padding: 80px 0;
  }
  .comntitle2 {
    margin-bottom: 10px;
  }
  .comntitle2,
  .per_day {
    font-size: var(--font22);
  }
  .from_to input {
    font-size: var(--font18);
    height: 40px;
  }
  .controlled p,
  .product_details p {
    font-size: var(--font16);
    line-height: 22px;
  }
  .from_to {
    margin: 15px 0;
  }
  .you_pay {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

    .Red_Btn {
        padding: 5px 70px;
    }
    .datediv {
        margin-bottom: 10px;
    }
    .secure_entry{
        gap: 15px;
        margin-top: 15px;
    }
    .top_search .MuiGrid-item button,
    header .MuiGrid-item button {
        padding: 0 10px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .list-view li {
    width: 30.8%;
  }
  #Product_Owl {
    width: 100%;
  }
  .location_map {
    height: 400px;
  }
  #Product_Owl,
  #Product_Owl .owl-stage-outer,
  #Product_Owl .owl-stage,
  #Product_Owl .owl-item,
  #Product_Owl .item,
  #Product_Owl img {
    width: 100%;
    height: 100%;
  }

  #Product_Owl img {
    object-fit: cover;
  }
}

@media screen and (max-width: 1190px) {
  :root {
    --font16: 16px;
    --font18: 18px;
    --font20: 20px;
    --font22: 22px;
    --font28: 28px;
    --font45: 36px;
  }
  .comntitle2 {
    font-size: var(--font22);
  }
  #Product_Owl,
  #Product_Owl .owl-stage-outer,
  #Product_Owl .owl-stage,
  #Product_Owl .owl-item,
  #Product_Owl .item,
  #Product_Owl img {
    width: 100%;
    height: 100%;
  }
  #Product_Owl img {
    object-fit: cover;
  }
  .controlled p,
  .product_details p {
    font-size: var(--font16);
    line-height: 24px;
  }

  .comnpadding {
    padding: 80px 0;
  }

  .comntitle2 {
    margin-bottom: 10px;
  }

  .comntitle2,
  .per_day {
    font-size: var(--font22);
  }

  .from_to input {
    font-size: var(--font18);
    height: 40px;
  }

  .controlled p,
  .product_details p {
    font-size: var(--font16);
    line-height: 22px;
  }

  .from_to {
    margin: 15px 0;
  }

  .you_pay {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  .Red_Btn {
    padding: 5px 70px;
  }

  .datediv {
    margin-bottom: 10px;
  }

  .secure_entry {
    gap: 15px;
    margin-top: 15px;
  }

  .comnpadding {
    padding: 60px 0;
  }

  #popular .item {
    padding: 10px;
  }

  .card-footer button {
    padding: 10px 15px;
    padding: 10px 15px;
  }

  .work_content h3 {
    font-size: var(--font18);
  }

  .stories_item {
    padding: 15px;
  }

  .List_Items .list-view {
    gap: 15px;
  }
  .List_Items .list-view li {
    width: 31.8%;
  }
  .card-footer strong {
    padding-left: 10px;
  }
  .filterprice .css-7jxisr {
    padding: 15px;
  }

  .main_slider li {
    width: 19.3%;
  }
  .location_map {
    height: 350px;
  }
  .list_storage li {
    font-size: var(--font16);
  }
}

@media screen and (max-width: 991px) {
  #stories .owl-stage-outer {
    padding: 70px 0 20px 0;
  }

  #stories .owl-stage-outer {
    padding: 70px 0 20px 0;
  }

  .ft_menu a {
    margin: 10px;
  }

  .MuiBox-root.css-1mek5iq {
    flex-direction: column;
    border-radius: 10px;
  }

  .logo {
    top: -50px;
    padding-bottom: 0;
  }

  .logo img {
    max-width: 200px;
  }

  .banner_serch {
    border-radius: 10px;
    width: 96%;
  }

  header .MuiBox-root.css-1mek5iq {
    padding: 10px;
    flex-direction: row;
    margin-top: 30px;
  }

  .top_search .MuiGrid-item {
    max-width: 47%;
    margin: 5px;
    border-right: none;
    border-bottom: 1px solid var(--lightblack);
  }

  .top_search .MuiGrid-item:nth-child(4) {
    border-bottom: none;
  }

  .top_search .MuiGrid-item:first-child {
    max-width: 100%;
  }

  .MuiBox-root.css-1mek5iq .Browse_Btn {
    min-width: inherit;
    width: 100%;
    border-radius: 10px;
    text-align: left;
  }

  .top_search .MuiGrid-item input,
  .top_search .MuiGrid-item button {
    padding: 10px;
  }

  .banner_category {
    flex-direction: column;
  }

  .banner_category_slider,
  .banner_category_slider {
    width: 100%;
  }

  .filterbtn {
    position: absolute;
    top: 35px;
    right: 65px;
  }

  .top_search .SearchIcon_Btn {
    position: absolute;
    right: 10px;
    top: 15px;
  }

  .filterbtn span,
  #Rentals .owl-nav button.owl-next,
  #Storage .owl-nav button.owl-next,
  #Rentals .owl-nav button.owl-prev,
  #Storage .owl-nav button.owl-prev {
    display: none;
  }

  .banner_category span {
    font-size: var(--font16);
  }
  .css-4owfk8-MuiInputBase-root-MuiInput-root {
    width: 60px;
  }
  .List_Items .list-view {
    gap: 10px;
  }
  .List_Items .list-view li {
    width: 48.8%;
  }
  .card-footer button {
    padding: 5px 10px;
    font-size: var(--font16);
  }
  .card-footer strong {
    font-size: var(--font16);
  }
  header .MuiGrid-grid-md-3,
  header .MuiGrid-grid-md-2 {
    max-width: 50%;
    margin: 10px 0;
  }
  header .MuiBox-root.css-1mek5iq::after {
    width: 100px;
    border-radius: 0;
  }
  header .MuiGrid-item button {
    justify-content: flex-start;
  }
  .main_slider li {
    width: 32.5%;
  }
  .banner_serch .MuiBox-root.css-1mek5iq::after {
    display: none;
  }
  #Product_Owl {
    height: auto;
    margin-bottom: 30px;
  }
  .offers {
    border-right: none;
    padding-right: 0;
  }
  .main_profile .btn_button {
    width: auto;
    margin: auto;
  }
  .list_storage li {
    width: 33%;
  }
}

@media screen and (max-width: 767px) {
  :root {
    --font45: 30px;
  }
  .how_works ul::after,
  .work_icon::before,
  .work_icon::after,
  .ft_menu a::after {
    display: none;
  }
  .main_slider li {
    width: 32%;
  }
  .logo {
    top: -40px;
  }
  .banner_serch {
    top: 55%;
  }
  .how_works ul li {
    width: 100%;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--lightblack);
  }

  .how_works ul li:last-child {
    border-bottom: none;
  }

  .how_works ul li:nth-child(even) {
    flex-direction: column;
  }

  .how_works ul li:nth-child(odd) .work_icon,
  .how_works ul li:nth-child(even) .work_icon {
    margin: auto;
  }

  .how_works ul li:nth-child(odd) .work_content,
  .how_works ul li:nth-child(even) .work_content {
    margin-top: 20px;
    margin-bottom: 0;
    min-height: inherit;
  }

  footer {
    padding: 30px 0;
  }

  .ft_menu li {
    width: 48%;
  }

  .ft_menu li a {
    font-size: var(--font16);
    margin: 5px 0;
  }

  .copy {
    justify-content: center;
    margin-top: 20px;
  }

  .copy span {
    display: block;
    margin-bottom: 15px;
  }
  .List_Items .list-view {
    margin-top: 30px;
  }
  .List_Items .list-view li {
    width: 100%;
  }
  .banner_serch .MuiBox-root.css-1mek5iq::after {
    display: none;
  }
  header .MuiBox-root.css-1mek5iq {
    flex-direction: column;
    position: relative;
    padding-bottom: 0;
  }
  header .MuiBox-root.css-1mek5iq::after {
    width: 100%;
    height: 50px;
    position: absolute;
    top: auto;
    bottom: 0;
  }
  header .SearchIcon_Btn {
    margin: 2px;
    width: 100%;
  }
  .rdrDateRangePickerWrapper {
    flex-direction: column;
    flex-wrap: wrap;
    overflow: auto;
  }
  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }

  .rdrInputRanges {
    display: flex;
    flex-direction: row;
  }
  .rdrStaticRanges {
    flex-direction: row !important;
    flex-wrap: wrap;
  }
  .sidebar {
    display: none;
  }
  .mob_fiterbtn,
  .sidebar_close {
    display: block;
  }
  .sidebar.show {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 1300;
    width: 100%;
    max-width: 300px;
  }
  .secure_entry li {
    width: 100%;
  }
  .list_storage {
    gap: 5px 15px;
  }
  .list_storage li {
    width: 48%;
  }
  .other_details li {
    margin-right: 20px;
    width: 45%;
    margin-bottom: 10px;
  }
  .other_details li:nth-child(even) {
    margin-right: 0;
  }
}

@media screen and (max-width: 525px) {
  .list_storage li {
    width: 100%;
    padding: 3px 3px 3px 20px;
  }
  .from_to {
    gap: 10px;
    justify-content: space-between;
  }
  .from_to input {
    font-size: var(--font16);
    padding: 5px 10px;
    max-width: 150px;
  }
  .pricice_div {
    display: block;
  }
  .profile_content {
    text-align: left;
    margin-top: 15px;
  }
  .Red_Btn {
    padding: 5px 40px;
  }
  .location_map {
    height: 250px;
  }
  .people_view,
  .top_saying {
    flex-wrap: wrap;
  }
  .top_saying .btn_button {
    margin-top: 15px;
  }
  .customer_review {
    margin-top: 20px;
  }
  .customer_review li {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .customer_review li p {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 360px) {
}
